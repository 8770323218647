@font-face {
    font-family: 'Intro Bold';
    src: url('/fonts/Intro-Bold.eot');
    src: url('/fonts/Intro-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Intro-Bold.woff') format('woff'),
    url('/fonts/Intro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Intro Regular';
    src: url('/fonts/Intro-Regular.eot');
    src: url('/fonts/Intro-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Intro-Regular.woff') format('woff'),
    url('/fonts/Intro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Intro Book';
    src: url('/fonts/Intro-Book.eot');
    src: url('/fonts/Intro-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Intro-Book.woff') format('woff'),
    url('/fonts/Intro-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin for-mobile() {
    @media (max-width: 640px) {
        @content;
    }
}