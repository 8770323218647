@import 'mixin';

$bgcolor: rgb(9, 2, 51);
$navcolor_initial: rgba(0, 161, 154, 1);

* {
  box-sizing: border-box;
}

body {
  color: white;
  margin: 0;
  font-family: 'Intro Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgcolor;
}

a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}


.navigation {
  position: sticky;
  background-color: rgba(9, 2, 51, .7);
  height: 128px;
  top: 0;
  padding: 0 10px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include for-mobile {
    height: 48px;
  }
  &--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #nav-toggle-mobile {
    display: none;
    @include for-mobile {
      display: block;
      color: white;
      font-size: 24px;
      line-height: 96px;
      float: right;
      cursor: pointer;
      outline: none;
      @include for-mobile {
        line-height: 48px;
      }
    }
    span {
      font-size: 14px;
      line-height: 48px;
    }
  }
  #phone-toggle-mobile {
    display: none;
    cursor: pointer;
    @include for-mobile {
      display: block;
      color: white;
      font-size: 24px;
      line-height: 48px;
      float: left;
      outline: none;
    }
    span {
      display: inline-block;
      font-size: 14px;
      line-height: 48px;
    }
  }
  .header-contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% / 3);
    text-align: right;
    &--phones {
      display: flex;
      flex-direction: column;
    }
    &--social {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    a {
      line-height: 32px;
      color: white;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      .fas.fa-phone {
        margin-right: 4px;
      }
      padding: 0 10px;
      border-right: 1px solid rgba(0,0,0,.5);
      transition: all .4s;
      &:last-child {
        border-right: none;
      }
      &:hover {
        background: linear-gradient( rgba(0,0,0,.3), rgba(0,0,0,0));
      }
    }
    @include for-mobile {
      display: none;
      &.open {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgb(9, 2, 51);
        top: 48px;
        height: calc(100vh - 48px);
        width: 100vw;
        left: 0;
        right: 0;
        a {
          height: auto;
          line-height: 96px;
          text-align: center;
        }
      }
    }
  }
  .header-logo {
    height: 128px;
    padding: 4px;
    @include for-mobile {
      width: auto;
      height: 48px;
    }
    img {
      height: 80px;
      max-width: 100%;
      @include for-mobile {
        height: 40px;
        margin: 0 auto;
      }
      display: block;
      margin: 20px auto;
    }
  }
  nav {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    height:100%;
    margin: 0 20px;
    @include for-mobile {
      display: none;
      position: absolute;
      flex-direction: column;
      justify-content: flex-start;
    }
    li.toggle-menu {
      line-height: 48px;
      font-size: 24px;
      text-align: right;
    }
    li {
      list-style-type: none;
      line-height: 1;
      padding: 0 10px;
      @include for-mobile {
        padding: 10px;
      }
      &:hover {
        background: linear-gradient( rgba(0,0,0,.3), rgba(0,0,0,0));
      }
      a, button {
        cursor: pointer;
        font-family: 'Intro Bold';
        color: white;
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
        display: block;
        height: 100%;
        width: 100%;
        font-size: 18px;
        padding: 0;
        margin: 0;
      }
    } 
  }
  @include for-mobile {
    nav.open {
      display: flex;
      position: fixed;
      left: 0;
      right: 0;
      top: 48px;
      bottom: 0;
      width: 100%;
      height: calc(100vh - 48px);
      background-color: $bgcolor;
    }
  }
}
.navigation.nontransparent {
  background-color: $bgcolor;
}

header {
  height: 85vh;
  width: 100%;
  background: linear-gradient(160deg, rgba(0,161,154,1), rgba(0,161,154,0) 50%),
            linear-gradient(20deg, rgba(0,161,154,.8), rgba(144,1,16,0) 70%);
  margin-top: -128px;
  @include for-mobile() {
    height: 80vh;
    margin-top: 0;
  }
  padding-top: 20vh;
  position: relative;
  .slide-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &>div {
      width: 100%;
      height: 100%;
      & > .react-slideshow-container {
        width: 100%;
        height: 100%;
        & > .react-slideshow-fade-wrapper {
          height: 100%;
          width: 100%;
          & > .react-slideshow-fade-images-wrap {
            height: 100%;
            width: 100%;
          }
        }
      }
    } 
    .image-container {
      width: 100%;
      height: 100%;
      background-size: cover;
      position: relative;
      &--blur {
        position: absolute;
        top: 0;
        background-color: rgba(9, 2, 51, .5);
        width: 100%;
        height: 100%;
      }
    }
  }
  .header-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    .logo {
      margin: 0 auto;
      max-height: 40vh;
      margin-bottom: 50px;
      img {
        max-height: 40vh;
        max-width: 90vw;
      }
    }
    .time-place {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%;
      z-index: 2;
      &--item {
        min-width: 20vw;
        max-width: 50vw;
        @include for-mobile {
          min-width: 100%;
          max-width: 100%;
          padding: 10px;
          margin-bottom: 20px;
        }
        p {
          text-align: center;
          margin: 0;
        }
        &--icon > i {
          color: rgb(144, 1, 16);
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.brand-cloud {
  background-color: $bgcolor;
  padding: 240px 0;
  position: relative;
  overflow-y: hidden;
  &--content {
    z-index: 3;
    padding: 0 10px;
    h3 {
      font-family: 'Intro Bold';
      text-align: center;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
}
.timeandplace {
  background-color: #00a19a;
  position: relative;
  color: white !important;
  z-index: 2;
  &--content {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    &--col {
      width: 50%;

      @include for-mobile {
        width: 100%;
        margin: 10px;
        p {
          margin: 10px 0;
          text-align: center;
          line-height: 24px;
        }
      }
      .fas, .far {
        font-size: 20px;
      }
      h5 {
        font-size: 20px;
        margin: 5px 0;
        font-family: 'Intro Bold';
      }
      input {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        font-family: 'Intro Regular';
      }
      .btn-small {
        font-family: 'Intro Bold';
        padding: 10px 20px;
        background: linear-gradient(100deg, rgba(144,1,16,1), $bgcolor);
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 5px;
        text-align: center;
        outline: none;
        border: none;
        cursor: pointer;
        @include for-mobile {
          font-size: 18px;
          padding: 5px 10px;
          width: 100%;
        }
      }
    }
  }
}
.delivery {
  position: relative;
  z-index: 2;
  &--content {
    padding: 240px 0;
    h3 {
      text-align: center;
      font-size: 24px;
    }
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      &--item {
        max-width: 40%;
        @include for-mobile {
          width: 100%;
          max-width: 100%;
          padding: 0 10px;
        }
        p {
          font-family: 'Intro Book';
          a {
            color: rgba(0, 161, 154, 1);
          }
          div.center-align {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 10px 0;
          }
          a.btn-small {
            margin: 0 auto;
            font-family: 'Intro Bold';
            padding: 10px 20px;
            background: linear-gradient(100deg, rgba(144,1,16,1), rgba(0, 161, 154, 1));
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            border-radius: 5px;
            text-align: center;
            outline: none;
            border: none;
            cursor: pointer;
            @include for-mobile {
              font-size: 18px;
              padding: 5px 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.gallery {
  position: relative;
  z-index: 2;
  background-color: rgb(144, 1, 16);
  &--content {
    z-index: 3;
    h3 {
      text-align: center;
      font-size: 24px;
      z-index: 999;
    }
    button {
      border: none;
    }
  }
}
.about {
  position: relative;
  z-index: 2;
  &--content {
    padding: 240px 0;
    @include for-mobile {
      padding: 120px 10px;
    }
    h3 {
      text-align: center;
      font-size: 24px;
    }
    p {
      line-height: 1.3;
      font-family: 'Intro Book';
    }
  }
}
.contact {
  position: relative;
  z-index: 2;
  background-color: #00a19a;
  &--content {
    padding: 10px 0;
    h3 {
      text-align: center;
      font-size: 24px;
    }
    &--row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .contact-form {
      order: 0;
      @include for-mobile {
        order: 1;
      }
    }
    .contact-icons {
      order: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @include for-mobile {
        order: 0;
      }
    }
    &--col {
      width: 50%;
      @include for-mobile {
        width: 100%;
      }
      i.fas {
        font-size: 48px;
        margin: 25px;
        @include for-mobile {
          margin: 5px;
        }
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      input, textarea {
        margin: 10px auto;
        width: 20vw;
        outline: none;
        padding: 5px 10px;
        border-radius: 5px;
        border: 2px solid transparent;
        transition: border-color .5s;
        font-family: 'Intro Book';
        @include for-mobile {
          width: calc(100% - 20px);
          margin: 10px;
          padding: 10px 20px;
        }
        &:hover, &:focus {
          border-color: rgb(144, 1, 16);
        }
        
      }
      textarea {
        min-height: 100px;
      }

    }
  }
}

.team {
  position: relative;
  z-index: 2;
  background-color: rgba(144,1,16,1);
  &--content {
    padding: 10px 0;
    h3 {
      text-align: center;
      font-size: 24px;
    }
  }
}

.large-gallery {
  position: relative;
  background: linear-gradient(160deg, rgba(0,161,154,1), rgba(0,161,154,0) 50%),
            linear-gradient(20deg, rgba(144,1,16,1), rgba(144,1,16,0) 70%);
  .svg-divider.top {
    margin-top: 96px;
  }
  &--content {
    padding-top: 120px;
    padding-bottom: 240px;
    min-height: 600px;
    h3 {
      font-size: 28px;
      text-align: center;
      font-family: 'Intro Bold';
    }
    .image-gallery, .image-gallery > * {
      outline: none;
    }
  }
}

footer {
  position: relative;
  padding-top: 240px;
  .footer--content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    &--map {
      width: 50%;
      @include for-mobile {
        width: calc(100% - 20px);
        margin: 10px;
        order: 1;
      }
      #gmap_canvas {
        width: 100%;
        height: 300px;
      }
    }
    &--contacts {
      width: 50%;
      @include for-mobile {
        width: calc(100% - 20px);
        margin: 10px;
        order: 0;
      }
      text-align: right;
      ul {
        list-style-type: none;
        li {
          color: rgba(0, 161, 154, 1);
          a {
            line-height: 1.5;
            text-decoration: none;
            color: white;

            &:hover > *:not(i) {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .copyright {
    background-color: rgba(9, 2, 51, .5);
    line-height: 40px;
    @include for-mobile {
      font-size: 12px;
      padding: 0 5px;
    }
    &--container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--author {
        color: rgb(20,20,100);
        a {
          color: rgb(20,20,120);
        }
      }
    }
  }
}

.svg-divider {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 240px;
  @include for-mobile {
    height: 120px;
  }
  z-index: 1;
  path {
    fill: #00a19a;
  }
}
.svg-divider.reverse {
  transform: rotate(180deg);
}
.svg-divider.red {
  path {
    fill: rgb(144, 1, 16);
  }
}
.svg-divider.dark {
  path {
    fill: rgb(0,51,51);
  }
}

.svg-divider.top {
  top: 0;
}
.svg-divider.inverse {
  transform: scale(-1, 1);
}
.svg-divider.reverse-inverse {
  transform: scale(1, -1);
}

.slick-slider {
  width: calc(100% - 75px);
  max-width: 1125px;
  margin: 0 auto;
  .slick-list {
    max-width:100vw;
  }
  .slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  .btn {
    font-family: 'Intro Bold';
    padding: 10px 20px;
    background: linear-gradient(100deg, rgba(144,1,16,1), rgba(0,161,154,1));
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
    @include for-mobile {
      font-size: 24px;
    }
  }
}
.jarallax > .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

picture.jarallax-img > img {
  width: 100%;
  @include for-mobile {
    height: 100%;
    width: auto;
  }
}

.parallax-blur {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 2, 51, .7);
  z-index: -1;
}
.fa-whatsapp {
  color: #4AC959;
  font-size: 110%;
  margin-left: 3px;
}

.fa-viber { 
  color: #665CAC;
  margin: 0 5px;
}